<template>
  <div class="bannerlc">
    <div class="top">
      <div class="logo">
        <v-img src="@/assets/logo-poiskpl.svg"></v-img>
      </div>
      <div class="shortText">онлайн-сервис по поиску автотранспорта <br/>
        в автосалонах России для лизинговых компаний
      </div>
    </div>
    <div class="center">
      <div class="_title">
        Преимущества для вас
      </div>
      <div class="inner">
        <div>
          <ul>
            <li>Быстрый поиск автотранспорта для клиента</li>
            <li>Большой выбор дилерских центров по всей России</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>Оперативное получение ответов о наличии автотранспорта</li>
            <li>On-line чат с менеджерами дилерских центров</li>
          </ul>
        </div>
        <div>
          <ul>
            <li>Вся работа в “одном окне” на любом устройстве</li>
            <li>Интуитивно понятный интерфейс</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="steps">
      <div class="_title">
        4 простых шага до автомобиля
      </div>
      <div class="inner">
        <div class="el">
          <div>
            <div>
              1 <br/>
              шаг
            </div>
            <div>
              <v-img
                  src="@/assets/banner/b1.jpg"
                  max-width="170px"
              ></v-img>
            </div>
          </div>
          <div>Создайте новый запрос на поиск</div>
        </div>
        <div class="el">
          <div>
            <div>
              2 <br/>
              шаг
            </div>
            <div>
              <v-img
                  src="@/assets/banner/b2.jpg"
                  max-width="170px"
              ></v-img>
            </div>
          </div>
          <div>Получите предложения</div>
        </div>
        <div class="el">
          <div>
            <div>
              3 <br/>
              шаг
            </div>
            <div>
              <v-img
                  src="@/assets/banner/b3.jpg"
                  max-width="170px"
              ></v-img>
            </div>
          </div>
          <div>Забронируйте автомобиль</div>
        </div>
        <div class="el">
          <div>
            <div>
              4 <br/>
              шаг
            </div>
            <div>
              <v-img
                  src="@/assets/banner/b4.jpg"
                  max-width="170px"
              ></v-img>
            </div>
          </div>
          <div>Заключите сделку</div>
        </div>
      </div>
    </div>
    <div class="sendButton">
      <v-btn
          color="primary"
          @click="showOfferDialog = true"
          x-large
      >Оставить заявку</v-btn>
    </div>
    <banner-offer-dialog
        :ShowDialog.sync = showOfferDialog
    ></banner-offer-dialog>
  </div>
</template>
<style lang="scss">
  body{
    background: #fff;
  }
  .bannerlc{
    width: 1280px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 22px;
    color: #494B58;
    font-family: Open Sans;
    .top{
      display: flex;
      margin-top: 26px;
      .logo{
        width: 216px;
        margin-right: 89px;
        img{
          width: 216px;
        }
      }
      .shortText{
        font-size: 26px;
        line-height: 35px;
        color: #3A76F0;
      }
    }
    .center{
      margin-top: 27px;
      .inner{
        display: flex;
        justify-content: space-around;
        margin-top: 43px;
        div{
          ul{
            li{
              list-style-type: none;
              display: flex;
              align-items: flex-start;
              margin-bottom: 15px;
            }
            li:before{
              color: #3A76F0;
              content: "*";
              padding-right: 10px;
              text-indent: -9999px;
              border-radius: 15px;
              width: 10px;
              height: 10px;
              display: flex;
              background-color: #3A76F0;
              margin-top: 5px;
              margin-right: 7px;
            }
          }
        }
      }
    }
    .steps{
      margin-top: 25px;
      .inner{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        .el{
          display: flex;
          width: 21%;
          justify-content: space-between;
          flex-direction: column;
          font-size: 16px;
          line-height: 22px;
          color: #101B53;
          > div:first-child{
            display: flex;
            width: 100%;
            justify-content: space-between;
              div:first-child{
                font-size: 26px;
                line-height: 35px;
                color: #101B53;
                text-align: center;
            }
          }
          > div:nth-child(2){
            margin-top: 10px;
          }
          img{
            width: 100%;
          }
        }
      }
    }
    ._title{
      font-size: 30px;
      line-height: 41px;
      color: #101B53;
      text-align: center;
    }
    .sendButton{
      text-align: center;
      margin-top: 40px;
        button{
          background: #101B53!important;
          width: 451px;
          height: 70px!important;
          .v-btn__content{
            font-size: 20px!important;
            line-height: 27px!important;
          }
        }
    }
  }
  @media only screen and (max-device-width: 480px){
    .bannerlc{
      width: calc(100% - 20px);
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      .top{
        flex-direction: column;
        .logo{
          margin: 0 auto;
          width: 146px;
        }
        .shortText{
          margin-top: 20px;
          text-align: center;
          font-size: 100%;
          line-height: initial;
        }
      }
      ._title{
        font-size: 150%;
        line-height: 150%;
      }
      .center{
        .inner{
          margin-top: 15px;
          flex-wrap: wrap;
        }
      }
      .steps{
        .inner{
          flex-wrap: wrap;
          margin-top: 20px;
          .el{
            width: 100%;
              br{
                display: none;
              }
              > div:first-child{
                flex-direction: column;
                  div:first-child{
                    font-size: 130%;
                    line-height: 130%;
                    margin: 0 auto;
                  }
              }
              > div:nth-child(2){
                margin-top: 5px;
                text-align: center;
                margin-bottom: 30px;
              }
          }
        }
      }
      .sendButton{
        button{
          width: 100%;
        }
      }
    }
  }
</style>
<script>

  import BannerOfferDialog from './components/BannerOfferDialog'

  export default {
    components: { BannerOfferDialog },
    data() {
      return {
        showOfferDialog: false
      }
    }
  }
</script>
