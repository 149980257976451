var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bannerlc"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"logo"},[_c('v-img',{attrs:{"src":require("@/assets/logo-poiskpl.svg")}})],1),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"steps"},[_c('div',{staticClass:"_title"},[_vm._v(" 4 простых шага до автомобиля ")]),_c('div',{staticClass:"inner"},[_c('div',{staticClass:"el"},[_c('div',[_vm._m(2),_c('div',[_c('v-img',{attrs:{"src":require("@/assets/banner/b1.jpg"),"max-width":"170px"}})],1)]),_c('div',[_vm._v("Создайте новый запрос на поиск")])]),_c('div',{staticClass:"el"},[_c('div',[_vm._m(3),_c('div',[_c('v-img',{attrs:{"src":require("@/assets/banner/b2.jpg"),"max-width":"170px"}})],1)]),_c('div',[_vm._v("Получите предложения")])]),_c('div',{staticClass:"el"},[_c('div',[_vm._m(4),_c('div',[_c('v-img',{attrs:{"src":require("@/assets/banner/b3.jpg"),"max-width":"170px"}})],1)]),_c('div',[_vm._v("Забронируйте автомобиль")])]),_c('div',{staticClass:"el"},[_c('div',[_vm._m(5),_c('div',[_c('v-img',{attrs:{"src":require("@/assets/banner/b4.jpg"),"max-width":"170px"}})],1)]),_c('div',[_vm._v("Заключите сделку")])])])]),_c('div',{staticClass:"sendButton"},[_c('v-btn',{attrs:{"color":"primary","x-large":""},on:{"click":function($event){_vm.showOfferDialog = true}}},[_vm._v("Оставить заявку")])],1),_c('banner-offer-dialog',{attrs:{"ShowDialog":_vm.showOfferDialog},on:{"update:ShowDialog":function($event){_vm.showOfferDialog=$event},"update:show-dialog":function($event){_vm.showOfferDialog=$event}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortText"},[_vm._v("онлайн-сервис по поиску автотранспорта "),_c('br'),_vm._v(" в автосалонах России для лизинговых компаний ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center"},[_c('div',{staticClass:"_title"},[_vm._v(" Преимущества для вас ")]),_c('div',{staticClass:"inner"},[_c('div',[_c('ul',[_c('li',[_vm._v("Быстрый поиск автотранспорта для клиента")]),_c('li',[_vm._v("Большой выбор дилерских центров по всей России")])])]),_c('div',[_c('ul',[_c('li',[_vm._v("Оперативное получение ответов о наличии автотранспорта")]),_c('li',[_vm._v("On-line чат с менеджерами дилерских центров")])])]),_c('div',[_c('ul',[_c('li',[_vm._v("Вся работа в “одном окне” на любом устройстве")]),_c('li',[_vm._v("Интуитивно понятный интерфейс")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" 1 "),_c('br'),_vm._v(" шаг ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" 2 "),_c('br'),_vm._v(" шаг ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" 3 "),_c('br'),_vm._v(" шаг ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" 4 "),_c('br'),_vm._v(" шаг ")])
}]

export { render, staticRenderFns }