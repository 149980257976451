
import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
// import { Http } from '@/apis/Http'
import { User } from '@/apis/User'

@Component({
  name: 'BannerOfferDialog'
})

export default class BannerOfferDialog extends Vue {
  @PropSync('ShowDialog', { type: Boolean }) syncShowDialog!: boolean

  user = {
    name: '',
    phone: '',
    email: ''
  }

  showSuccess = false

  sendMessage() {
    User.sendBannerUser(this.user)
    .then(({ data }) => {
      if (data.success === true) {
        this.showSuccess = true
        // this.showSuccessSystemNotification(data.msg)
      } else {
        // @ts-ignore
        this.showErrorSystemNotification(data.msg)
      }
    })
    .catch(() => {
      const msg = 'Что-то пошло не так'
      // @ts-ignore
      this.showErrorSystemNotification(msg)
    })
  }
}

